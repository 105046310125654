<template>
  <div class="inventory-content__container">
    <vue-element-loading :active="isLoad"/>
    <tabs class="mt-15px" @changed="changeTabActive">
      <tab name="Đơn đặt hàng" :tab_id="INVENTORY_ORDER_TAB.NHAP_MUA">
        <div v-if="tabActive == INVENTORY_ORDER_TAB.NHAP_MUA">
          <new-filter :id-filter-date="'don_dat_hang'" @change="handleFilterChange"/>
          <div class="mb-20px d-flex-space-between">
            <div class="heading-600">Danh sách đơn đặt hàng</div>
            <div class="d-flex-center">
              <div class="export-excel-btn"
                   @click="dowloadExcel()">
                <md-icon>file_download</md-icon>
                <span>Xuất Excel</span>
              </div>
              <div class="ml-10px zoom-out-btn"
                   @click="getOrderCoupon">
                <md-icon>autorenew</md-icon>
              </div>
              <div class="ml-10px zoom-out-btn"
                   @click="$modal.show('show-inventory-table-modal')">
                <md-icon>zoom_out_map</md-icon>
              </div>
            </div>
          </div>
          <div class="w_100 mb-10px">
            <md-button class="delete-coupon-btn" @click="showConfirmDeleteOrder"
                       v-if="orderSelectedRowKeys.length !== 0">
              <md-icon>delete</md-icon>
              <span>Xóa</span>
            </md-button>
            <md-button class="delete-coupon-btn" @click="showConfirmDeleteCouponNotInStock"
                       v-if="pagingParams.total > 0 && ($store.state.auth.user.manage_inventory || [636,842,119,1,2,311].includes(Number($store.state.auth.user.id)))">
              <md-icon>delete</md-icon>
              <span>Xóa các phiếu chưa về kho</span>
            </md-button>
<!--            <md-button class="delete-coupon-btn"  @click="showConfirmDeleteCouponByQuery"-->
<!--                       v-if="pagingParams.total > 0 && ($store.state.auth.user.manage_inventory || [636,842,119,1,2,311].includes(Number($store.state.auth.user.id)))">-->
<!--              <md-icon>delete</md-icon>-->
<!--              <span>Xóa {{ pagingParams.total }} phiếu</span>-->
<!--            </md-button>-->
            <div class="float-right mb-10px">
              <pagination-template :class="'float-right'" :params="pagingParams" @change-params="changePagingParam"/>
            </div>
          </div>
          <inventory-table border-y :columns="DON_DAT_HANG"
                    :scroll-width="1600"
                    :table-data="inventoryCouponData"
                    :cell-style-option="cellStyleOption"
                    :cell-span-option="cellSpanOption"
                    :checkbox-option="checkboxOption"
          />
        </div>
      </tab>
    </tabs>
    <detail-order-coupon-modal />
    <update-order-coupon-modal />
    <show-inventory-table-modal :total-stock-data="inventoryCouponData"
                                :total-stock-columns="DON_DAT_HANG"
                                :paging-params="pagingParams"
                                :hidden-export-btn="true"
                                @change-paging-params="changePagingParam"
                                @export="dowloadExcel()"/>
  </div>
</template>

<script>

import PaginationTemplate from "@/pages/Components/PaginationTemplate";
import {
  INVENTORY_COUPON_TYPE,
  INVENTORY_ORDER_TAB,
  INVENTORY_REPORT_EXPORT_TAB,
  INVENTORY_TYPE_OPTIONS
} from "@/const/const";
import moment from "moment";
import InventoryService from "@/store/services/InventoryService";
import ShowInventoryTableModal from "@/pages/Components/Modal/ShowInventoryTableModal";
import {helpers} from "../../../../../helper/helpers";
import DetailOrderCouponModal from "@/pages/Components/Modal/DetailOrderCouponModal";
import NewFilter from "@/pages/Workplace/Pages/Inventory/NewFilter";
import InventoryTable from "../Table";
import UpdateOrderCouponModal from "@/pages/Components/Modal/UpdateOrderCouponModal";

export default {
  name: "OrderCouponContent",
  components: {
    NewFilter,
    DetailOrderCouponModal,
    PaginationTemplate,
    ShowInventoryTableModal,
    InventoryTable,
    UpdateOrderCouponModal
  },
  data() {
    return {
      INVENTORY_ORDER_TAB,
      INVENTORY_TYPE_OPTIONS,
      isLoad: false,
      inventoryId: this.$route.params.inventory_id,
      tabActive: INVENTORY_REPORT_EXPORT_TAB.NHAP_MUA,
      totalStockParams: {
        start_date: moment().startOf('month').format('YYYY-MM-DD'),
        end_date: moment().format('YYYY-MM-DD'),
        internal_code: '',
        inventory_id: this.$route.params.inventory_id,
        shipping_method: '',
        sync_history_id: '',
      },
      inventoryCouponData: [],
      checkboxOption: {
        // row select change event
        selectedRowChange: ({row, isSelected, selectedRowKeys}) => {
          this.orderSelectedRowKeys = selectedRowKeys;
        },
        // selected all change event
        selectedAllChange: ({isSelected, selectedRowKeys}) => {
          this.orderSelectedRowKeys = selectedRowKeys;
        },
      },
      orderSelectedRowKeys: [],
      totalStockData: [],
      pagingParams: {
        page: 1,
        perPage: 50,
        lastPage: 1,
        total: 0,
      },
      virtualScrollOption: {
        // 是否开启
        enable: true,
      },
      cellSpanOption: {
        bodyCellSpan: ({row, column, rowIndex}) => {
          if (rowIndex === 0) {
            if (column.field === "stt") {
              return {
                colspan: 8,
                rowspan: 1,
              };
            } else if (column.type === "checkbox" || column.field === "detail_btn"
                || column.field === "code" || column.field === "time"
                || column.field === "manager" || column.field === "product_sku" || column.field === "product_name") {
              return {
                colspan: 0,
                rowspan: 0,
              };
            }
          }
          if (row['rowspan'] > 1) {
            if (column.field !== "product_sku"
                && column.field !== "product_name"
                && column.field !== "product_quantity"
                && column.field !== "product_price"
                && column.field !== "product_rate"
                && column.field !== "product_shipping_fee"
                && column.field !== "product_total_price"
                && column.field !== "product_cost_price") {
              return {
                rowspan: row['rowspan'],
                colspan: 1,
              };
            }
          } else if (row['rowspan'] === 0) {
            if (column.field !== "product_sku"
                && column.field !== "product_name"
                && column.field !== "product_quantity"
                && column.field !== "product_price"
                && column.field !== "product_rate"
                && column.field !== "product_shipping_fee"
                && column.field !== "product_total_price"
                && column.field !== "product_cost_price") {
              return {
                rowspan: 0,
                colspan: 0,
              };
            }
          }
        },
      },
      DON_DAT_HANG: [
        {
          field: "",
          key: "order_coupon_r",
          // type=checkbox
          type: "checkbox",
          title: "",
          width: 50,
          align: "center",
        },
        {
          field: "detail_btn", key: "order_coupon_z", title: "", align: "center",
          renderBodyCell: ({row, column, rowIndex}, h) => {
            return (
                <span class="material-symbols-outlined show-detail"
                      on-click={() => this.getProductDetail(row)}>info</span>
            );
          },
        },
        {field: "stt", key: "order_coupon_a", title: "STT", align: "center"},
        {field: "code", key: "order_coupon_b", title: "Số phiếu", align: "left", width: 100},
        {field: "internal_code", key: "order_coupon_b_1", title: "Mã đơn hàng nội bộ", align: "left", width: 200},
        {field: "time", key: "order_coupon_d", title: "Ngày tạo", align: "center"},
        {field: "vendor_name", key: "order_coupon_e", title: "Nhà cung cấp", align: "left"},
        {field: "manager", key: "order_coupon_d_11", title: "Đối tượng", align: "left"},
        {field: "created_by", key: "order_coupon_d_12", title: "Người lập phiếu", align: "left"},
        {field: "product_sku", key: "order_coupon_sp_1", title: "SKU", align: "left"},
        {field: "product_name", key: "order_coupon_sp_2", title: "Sản phẩm", align: "left"},
        {field: "product_quantity", key: "order_coupon_sp_3", title: "Số lượng", align: "right"},
        {field: "product_price", key: "order_coupon_sp_4", title: "Đơn giá", align: "right"},
        {field: "product_shipping_fee", key: "order_coupon_sp_5", title: "Phí vận chuyển (VND)", align: "right"},
        {field: "product_rate", key: "order_coupon_sp_6", title: "Tỉ giá", align: "right"},
        {field: "product_total_price", key: "order_coupon_sp_7", title: "Tổng tiền (VND)", align: "right"},
        {field: "product_cost_price", key: "order_coupon_sp_8", title: "Giá nhập (VND)", align: "right"},
        {field: "inventory_main_name", key: "order_coupon_c", title: "Kho hàng", align: "center"},
        {field: "tracking", key: "order_coupon_g", title: "Mã tracking VC", align: "center"},
        {field: "shipping_method", key: "order_coupon_m_1", title: "Phương thức vận chuyển", align: "center"},
        {field: "due_date", key: "order_coupon_dd_1", title: "Ngày dự kiến về kho", align: "center"},
        {field: "description", key: "order_coupon_h", title: "Ghi chú", align: "left",},
        {
          field: "import_status", key: "order_coupon_ss", title: "Tình trạng nhập kho", align: "center", width: 150,
          renderBodyCell: ({row, column, rowIndex}, h) => {
            if (rowIndex === 0) {
              return '';
            }
            let importStatus = '';
            let backgroundClass = '';
            switch (row.import_status) {
              case 1:
                importStatus = 'Chưa về kho';
                backgroundClass = 'background_r400';
                break;
              case 2:
                importStatus = 'Thiếu';
                backgroundClass = 'background_o400';
                break;
              case 3:
                importStatus = 'Thừa';
                backgroundClass = 'background_b400';
                break;
              case 4:
                importStatus = 'Đủ';
                backgroundClass = 'background_g400';
                break;
            }
            return (
                <span class={'inventory-status-display ' + backgroundClass}>{importStatus}</span>
            );
          },
        },
        {
          field: "payment_status", key: "order_coupon_ps", title: "Tình trạng thanh toán", align: "center"
        },
        {
          field: "action",
          key: "order_coupon_ac_1",
          title: "Hành động",
          align: "center",
          renderBodyCell: ({row, column, rowIndex}, h) => {
            if (rowIndex !== 0) {
              return (
                  <div class="d-flex">
                    <button class="p-2px cursor-pointer tooltip" on-click={() => this.createImportCoupon(row)}>
                      <md-icon>add</md-icon>
                      <span class="tooltiptext">Tạo phiếu nhập mua</span>
                    </button>
                    <button class="p-2px cursor-pointer tooltip ml-5px" on-click={() => this.updateOrderCoupon(row)}>
                      <md-icon>edit</md-icon>
                      <span class="tooltiptext">Chỉnh sửa đơn hàng</span>
                    </button>
                  </div>
              );
            }
          },

        },
      ],
      cellStyleOption: {
        bodyCellClass: ({row, column, rowIndex}) => {
          if (rowIndex === 0) {
            return "background_blue_100 text-400-medium";
          }
          if (column.field === "product_cost_price") {
            return "border-right-important";
          } else if (column.field === "action") {
            return "action-column";
          }
        },
      },
      orderCouponList: [],
    }
  },
  computed: {},
  created() {
  },

  watch: {
    '$route.params.inventory_id': function (newVal) {
      this.inventoryId = newVal;
      this.totalStockParams.inventory_id = newVal;
      let today = moment().format('YYYY-MM-DD')
      let firstDateOfMonth = moment().startOf('month').format('YYYY-MM-DD');
      this.totalStockParams.start_date = firstDateOfMonth;
      this.totalStockParams.end_date = today;
      this.getOrderCoupon();
    },
  },
  methods: {
    handleFilterChange(filters) {
      this.refreshPagingParams();
      this.totalStockParams.end_date = filters.end_date;
      this.totalStockParams.start_date = filters.start_date;
      this.totalStockParams.internal_code = filters.internal_code;
      this.totalStockParams.shipping_method = filters.shipping_method;
      this.totalStockParams.sync_history_id = filters.sync_history_id;
      this.getOrderCoupon();
    },
    changeTabActive(selectedTab) {
      this.tabActive = selectedTab.tab.$attrs.tab_id;
      this.totalStockParams = {
        start_date: moment().startOf('month').format('YYYY-MM-DD'),
        end_date: moment().format('YYYY-MM-DD'),
        product_id: [],
        inventory_id: this.$route.params.inventory_id,
        type: this.tabActive
      };
      this.refreshPagingParams();
      this.getOrderCoupon();
    },
    async getOrderCoupon() {
      this.inventoryCouponData = [];
      this.orderSelectedRowKeys = [];
      this.isLoad = true;
      let paging = {
        page: this.pagingParams.page,
        per_page: this.pagingParams.perPage
      };
      let couponParams = {...this.totalStockParams, ...paging};

      await this.getCouponOverview(couponParams);

      InventoryService.getOrderCoupons(couponParams).then(res => {
        if (res) {
          this.pagingParams.lastPage = res.meta.last_page
          this.pagingParams.total = res.meta.total;
          this.orderCouponList = res.data;
          for (let i = 0; i < res.data.length; i++) {
            let inventoryCouponColumn = {};
            inventoryCouponColumn.id = res.data[i].id;
            let index = (this.pagingParams.page - 1) * this.pagingParams.perPage;
            inventoryCouponColumn.stt = i + 1 + index;
            inventoryCouponColumn.code = res.data[i].code;
            inventoryCouponColumn.internal_code = res.data[i].internal_code;
            inventoryCouponColumn.inventory_main_id = res.data[i].inventory.id;
            inventoryCouponColumn.inventory_main_name = res.data[i].inventory ? res.data[i].inventory.name : '';
            inventoryCouponColumn.time = moment(res.data[i].created_at).format('DD-MM-YYYY');
            inventoryCouponColumn.vendor_name = res.data[i].vendor ? res.data[i].vendor.name : '';
            inventoryCouponColumn.products = res.data[i].products;
            inventoryCouponColumn.status = res.data[i].status;
            inventoryCouponColumn.created_by = res.data[i].created_by.fullname;
            inventoryCouponColumn.rowKey = res.data[i].id;
            inventoryCouponColumn.receipt_internal_code = res.data[i].receipt_internal_code;
            inventoryCouponColumn.manager = res.data[i].manager ? res.data[i].manager.fullname : '';
            inventoryCouponColumn.tracking = res.data[i].tracking;
            inventoryCouponColumn.shipping_method = res.data[i].shipping_method;
            inventoryCouponColumn.due_date = moment(res.data[i].due_date).format('DD-MM-YYYY');
            inventoryCouponColumn.description = res.data[i].description;
            inventoryCouponColumn.payment_status = res.data[i].payment_status;
            inventoryCouponColumn.import_status = res.data[i].import_status;
            inventoryCouponColumn.rowspan = 1;
            let productReceipt1 = res.data[i].product_receipts[0];
            inventoryCouponColumn.product_sku = productReceipt1 ? productReceipt1.product.sku : '';
            inventoryCouponColumn.product_name = productReceipt1 ? productReceipt1.product.goods.name : '';
            inventoryCouponColumn.product_quantity = productReceipt1 ? parseInt(productReceipt1.quantity).toLocaleString() : '0';
            inventoryCouponColumn.product_price = productReceipt1 ? parseInt(productReceipt1.price).toLocaleString() : '0';
            inventoryCouponColumn.product_shipping_fee = productReceipt1 ? parseInt(productReceipt1.shipping_fee).toLocaleString() : '0';
            inventoryCouponColumn.product_rate = productReceipt1 ? productReceipt1.rate : '0';
            inventoryCouponColumn.product_total_price = productReceipt1 ? parseInt(productReceipt1.total_price).toLocaleString() : '0';
            inventoryCouponColumn.product_cost_price = productReceipt1 ? parseInt(productReceipt1.total_price / productReceipt1.quantity).toLocaleString() : '0';

            switch (res.data[i].payment_status) {
              case 1:
                inventoryCouponColumn.payment_status = 'Thanh toán đủ';
                break;
              case 2:
                inventoryCouponColumn.payment_status = 'Đặt cọc';
                break;
              case 3:
                inventoryCouponColumn.payment_status = 'Chưa thanh toán phí vận chuyển';
                break;
            }

            inventoryCouponColumn.import_status = res.data[i].import_status;

            this.inventoryCouponData.push(inventoryCouponColumn);

            if (res.data[i].product_receipts.length > 1) {
              inventoryCouponColumn.rowspan = res.data[i].product_receipts.length;
              for (let j = 1; j < res.data[i].product_receipts.length; j++) {
                let productReceipt = res.data[i].product_receipts[j];
                let subInventoryColumn = this.createInventoryColumnDefault();
                subInventoryColumn.product_sku = productReceipt.product.sku;
                subInventoryColumn.product_name = productReceipt.product.goods.name;
                subInventoryColumn.product_quantity = parseInt(productReceipt.quantity).toLocaleString();
                subInventoryColumn.product_price = parseInt(productReceipt.price).toLocaleString();
                subInventoryColumn.product_shipping_fee = parseInt(productReceipt.shipping_fee).toLocaleString();
                subInventoryColumn.product_rate = productReceipt.rate;
                subInventoryColumn.product_total_price = parseInt(productReceipt.total_price).toLocaleString();
                subInventoryColumn.product_cost_price = parseInt(productReceipt.total_price / productReceipt.quantity).toLocaleString();

                this.inventoryCouponData.push(subInventoryColumn);
              }
            }
          }
        }
        console.log(this.inventoryCouponData);
        this.isLoad = false;
      });
    },
    createInventoryColumnDefault() {
      let inventoryCouponColumn = {};
      inventoryCouponColumn.id = '';
      inventoryCouponColumn.stt = '';
      inventoryCouponColumn.code = '';
      inventoryCouponColumn.internal_code = '';
      inventoryCouponColumn.inventory_main_name = '';
      inventoryCouponColumn.time = '';
      inventoryCouponColumn.vendor = '';
      inventoryCouponColumn.products = '';
      inventoryCouponColumn.status = '';
      inventoryCouponColumn.created_by = '';
      inventoryCouponColumn.rowKey = '';
      inventoryCouponColumn.receipt_internal_code = '';
      inventoryCouponColumn.manager = '';
      inventoryCouponColumn.tracking = '';
      inventoryCouponColumn.shipping_method = '';
      inventoryCouponColumn.due_date = '';
      inventoryCouponColumn.description = '';
      inventoryCouponColumn.payment_status = '';
      inventoryCouponColumn.rowspan = 0;

      return inventoryCouponColumn;
    },
    changePagingParam(value) {
      this.pagingParams = value;
      this.getOrderCoupon();
    },
    refreshPagingParams() {
      this.pagingParams = {
        page: 1,
        perPage: 50,
        lastPage: 1,
        total: 0,
      };
    },
    getProductDetail(row) {
      InventoryService.getOrderCouponDetail({order_coupon_id: row.id}).then(res => {
        if (res && res.data) {
          this.$modal.show('detail-order-coupon-modal', {data: res.data, isOrderCoupon: true});
        } else {
          this.$store.dispatch("alerts/error", res.message);
        }
      });
    },
    showConfirmDeleteOrder() {
      if (this.orderSelectedRowKeys.length === 0) {
        this.$store.dispatch("alerts/error", 'Bạn hãy chọn đơn đặt hàng xóa!');
        return;
      }
      this.$modal.show('dialog', {
        title: 'Xóa đơn đặt hàng?',
        text: `Bạn có chắc chắn muốn xóa các đơn đặt hàng đã chọn?`,
        buttons: [
          {
            title: 'Không',
            handler: () => {
              this.$modal.hide('dialog');
            }
          },
          {
            title: 'Xóa',
            handler: () => {
              this.deleteInventoryOrder();
            }
          }
        ]
      })
    },
    deleteInventoryOrder() {
      InventoryService.deleteMultiOrder({
        ids: this.orderSelectedRowKeys
      }).then(res => {
        if (res.status) {
          this.getOrderCoupon();
          this.$modal.hide('dialog');
          this.$store.dispatch("alerts/success", 'Bạn đã xóa thành công');
        }
      });
    },
    showConfirmDeleteCouponByQuery() {
      const time = (this.pagingParams.total/3000).toFixed(1)
      this.$modal.show('dialog', {
        title: 'Xóa phiếu?',
        text: `Bạn có chắc chắn muốn xóa các phiếu theo filter đã chọn?. Thời gian dự tính ${time < 1 ? 1 : time} phút.`,
        buttons: [
          {
            title: 'Không',
            handler: () => {
              this.$modal.hide('dialog');
            }
          },
          {
            title: 'Xóa',
            handler: () => {
              this.deleteMultiOrderByQuery();
            }
          }
        ]
      })
    },
    deleteMultiOrderByQuery() {
      InventoryService.deleteMultiOrderByQuery({
        start_date: this.totalStockParams.start_date,
        end_date: this.totalStockParams.end_date,
      }).then(res => {
        if (res.status) {
          this.getOrderCoupon();
          this.$modal.hide('dialog');
          this.$store.dispatch("alerts/success", 'Đang xóa phiếu, vui lòng chờ sau ít phút.');
        }
      });
    },

    showConfirmDeleteCouponNotInStock() {
      const time = (this.pagingParams.total/3000).toFixed(1)
      this.$modal.show('dialog', {
        title: 'Xóa phiếu?',
        text: `Bạn có chắc chắn muốn xóa các phiếu chưa về kho theo filter đã chọn?. Thời gian dự tính ${time < 1 ? 1 : time} phút.`,
        buttons: [
          {
            title: 'Không',
            handler: () => {
              this.$modal.hide('dialog');
            }
          },
          {
            title: 'Xóa',
            handler: () => {
              this.deleteCouponNotInStock();
            }
          }
        ]
      })
    },
    deleteCouponNotInStock() {
      InventoryService.deleteCouponNotInStock(this.totalStockParams).then(res => {
        if (res.status) {
          this.getOrderCoupon();
          this.$modal.hide('dialog');
          this.$store.dispatch("alerts/success", 'Đang xóa phiếu, vui lòng chờ sau ít phút.');
        }
      });
    },

    dowloadExcel(subInventory = false) {
      const inventoryId = this.inventoryId;
      let baseUrl = process.env.VUE_APP_API_INVENTORY_BASE_URL + '/v1';
      let url = `${baseUrl}/receipt/${inventoryId}/export-excel?${helpers.convertObjToQueryUrl(
          this.totalStockParams
      )}`;
      window.open(url, '_blank');
    },

    createImportCoupon(row) {
      let orderCouponData = this.orderCouponList.find(obj => obj.id === row.id);
      this.$modal.show('add-multiple-product-modal', {
        type: INVENTORY_COUPON_TYPE.NHAP_MUA,
        order_coupon_data: orderCouponData,
        inventory_id: row.inventory_main_id,
        inventory_name: row.inventory_main_name
      })
    },

    updateOrderCoupon(row) {
      InventoryService.getOrderCouponDetail({order_coupon_id: row.id}).then(res => {
        if (res && res.data) {
          this.$modal.show('update-order-coupon-modal', {data: res.data});
        } else {
          this.$store.dispatch("alerts/error", res.message);
        }
      });
    },

    async getCouponOverview(couponParams) {
      let params = {...couponParams};
      params.type = 2; // Phiếu đặt hàng, 2 là đơn đặt hàng
      InventoryService.getCouponOverview(params).then(res => {
        if (res) {
          this.couponOverview = res;
          let firstRow = {};
          firstRow.stt = "Tổng";
          firstRow.product_quantity = this.couponOverview ? parseInt(this.couponOverview.quantity).toLocaleString() : '0';
          firstRow.product_price = this.couponOverview ? parseInt(this.couponOverview.price).toLocaleString() : '0';
          firstRow.product_shipping_fee = this.couponOverview ? (this.couponOverview.shipping_fee ? parseInt(this.couponOverview.shipping_fee).toLocaleString() : '0') : '0';
          firstRow.product_rate = this.couponOverview ? this.couponOverview.rate : '0';
          firstRow.product_total_price = this.couponOverview ? parseInt(this.couponOverview.total_price).toLocaleString() : '0';
          firstRow.product_cost_price = this.couponOverview ? parseInt(this.couponOverview.total_price / this.couponOverview.quantity).toLocaleString() : '0';
          this.inventoryCouponData.unshift(firstRow);
        }
      });
    },
  }
}

</script>

<style lang="scss">
.inventory-content__container {
  .delete-coupon-btn {
    padding: 5px;
    margin: 0px 10px 0px 0px !important;
    background: #d0d0d0 !important;
    &:hover {
      background: #ff0000 !important;
    }
  }

  .border-right-important {
    border-right: 1px solid #eee !important;
  }

  .action-column {
    overflow: initial !important;
    position: relative;
  }

  .inventory-more-btn-wrapper {
    .inventory-more-dropdown {
      position: absolute;
    }
  }


}
</style>