<template>
<!--  <md-toolbar-->
<!--      v-if="router_name === 'inventory_detail'"-->
<!--      md-elevation="0"-->
<!--      class="md-transparent p-0px p-0px main-header" style="z-index: 1000"-->
<!--      :class="{-->
<!--      'md-toolbar-absolute md-white md-fixed-top': $route.meta.navbarAbsolute,-->
<!--    }"-->
<!--  >-->
<!--    -->
<!--  </md-toolbar>-->
  <div class="inventory-header" v-if="router_name === 'inventory_detail'">
    <div class="display-flex">
      <div class="tab-item text-400-semibold" :class="{active: inventoryTabActive === 'report'}"
           @click="changeInventoryTabActive('report')">Báo cáo kho
      </div>
      <div class="tab-item text-400-semibold" :class="{active: inventoryTabActive === 'import'}"
           @click="changeInventoryTabActive('import')">Nhập hàng
      </div>
      <div class="tab-item text-400-semibold" :class="{active: inventoryTabActive === 'export'}"
           @click="changeInventoryTabActive('export')">Xuất hàng
      </div>
      <div class="tab-item text-400-semibold" :class="{active: inventoryTabActive === 'transfer'}"
           @click="changeInventoryTabActive('transfer')">Chuyển kho
      </div>
      <div class="tab-item text-400-semibold" :class="{active: inventoryTabActive === 'order'}"
           @click="changeInventoryTabActive('order')">Đơn đặt hàng
      </div>
      <div class="tab-item text-400-semibold" :class="{active: inventoryTabActive === 'setting'}"
           v-if="[636,842,119,1,2,311].includes(Number($store.state.auth.user.id)) || $store.state.auth.user.manage_inventory"
           @click="changeInventoryTabActive('setting')">Cài đặt
      </div>
    </div>


    <div class="icon-list">
      <a class="text-400-semibold mr-5px" href="https://social.azagroup.asia/messenger/1" target="_blank">
        <img src="/img/sidebar/left/messenger-icon.svg" alt="">
      </a>
      <a class="text-400-semibold mr-5px" href="https://azagroup.asia/report" target="_blank">
        <img src="/img/sidebar/left/chart-icon.svg" alt="">
      </a>
      <a class="text-400-semibold mr-5px" href="https://azagroup.asia/don-hang" target="_blank">
        <img src="/img/sidebar/left/bag-icon.svg" alt="">
      </a>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      user: this.$store.state.auth.user,
      router_name: this.$route.name,
      category: this.$store.state.category.category,
      chatGroupSelected: this.$store.state.chat.chatGroupSelected,
      inventoryTabActive: this.$route.params.tab,
    };
  },
  watch: {
    '$route'(to, from) {
      this.router_name = to.name
    },
    '$store.state.category.category': function (newVal, oldVal) {
      this.category = newVal
    },
    '$store.state.chat.chatGroupSelected': function (newVal, oldVal) {
      this.chatGroupSelected = newVal
    },
    '$route.params.tab'(newV) {
      this.inventoryTabActive = newV;
    }
  },
  mounted() {
    this.router_name = this.$route.name;
  },
  created() {
    this.router_name = this.$route.name;
  },
  methods: {
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    minimizeSidebar() {
      if (this.$sidebar) {
        this.$sidebar.toggleMinimize();
      }
    },
    goToNotifications() {
      this.$router.push({name: "Notifications"});
    },
    goToUsers() {
      this.$router.push({name: "User Profile"});
    },
    getGroupTabLink(tab) {
      if (tab == null) {
        return "/groups";
      }
      return "/groups/" + tab;
    },
    openCreateGroupModal() {
      this.$store.commit("updateModalIsOpen", true);
      this.$modal.show('create-group-modal');
    },
    changeInventoryTabActive(tab) {
      this.$router.push(`/inventory/${this.$route.params.inventory_id}/${tab}`);
    }
  },
};
</script>
<style lang="scss">
.md-toolbar {
  //height: 75px;
  padding-left: 2px;
  box-shadow: 0px 1px 2px rgb(0 0 0 / 10%);
}

.logo-toolbar {
  width: 80px;
  height: 50px;
  left: 0px;
  top: 0px;
  background: #EDEFF5;
  border: 1px solid #d8dae5;
  border-radius: 4px;
}

.messenger.logo-toolbar {
  width: 50px !important;
  height: 50px !important;
  border-radius: 50%;
  border: 1px solid #d1d1d1;

  img {
    object-fit: cover;
  }
}

.logo-toolbar .logo {
  width: 75%;
}

.is_category {
  .material-icons {
    color: white !important;
  }
}

.member_count {
  display: flex;
  align-items: center;

  .md-icon {
    justify-content: flex-start;
    width: max-content;
    font-size: 20px !important;
  }
}

.md-toolbar.main-header {
  position: sticky;
  top: -1px;
}

.inventory-header {
  padding-left: 35px;
  height: 42px;
  width: 100%;
  display: flex;
  background: white;
  border-bottom: 1px solid #e4e4e4;
  justify-content: space-between;
  .tab-item {
    padding: 0 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: #52576F;
  }
  .tab-item.active {
    color: #FFFFFF;
    background-color: #5CA222;
  }
  .icon-list {
    display: flex;
    align-items: center;
    margin-right: 30px;
    padding-top: 4px;
    img {
      padding: 6px;
      cursor: pointer;
      border-radius: 4px;
      &:hover {
        background-color: #d3e2f9;
      }
    }
  }
}

</style>
