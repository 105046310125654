<template>
  <div class="inventory-content__container">
    <vue-element-loading :active="isLoad"/>
    <tabs class="mt-15px" @changed="changeTabActive">
      <tab name="Nhập điều chuyển" :tab_id="INVENTORY_REPORT_EXPORT_TAB.NHAP_DIEU_CHUYEN">
        <div v-if="tabActive === INVENTORY_REPORT_EXPORT_TAB.NHAP_DIEU_CHUYEN">
          <inventory-filter
              @change="handleFilterChange"
              :pick-type="[INVENTORY_TYPE_OPTIONS.KEYWORK, INVENTORY_TYPE_OPTIONS.SKU, INVENTORY_TYPE_OPTIONS.NEGATIVE_SKU, INVENTORY_TYPE_OPTIONS.GOODS, INVENTORY_TYPE_OPTIONS.STATUS]"
              :tab-active="tabActive"
              :id-filter-date="'nhap_dieu_chuyen'"
          ></inventory-filter>
          <div class="mb-20px d-flex-space-between">
            <div class="heading-600">Danh sách phiếu Nhập điều chuyển</div>
            <div class="d-flex-center">
              <div class="export-excel-btn"
                   @click="dowloadExcel">
                <md-icon>file_download</md-icon>
                <span>
                Xuất Excel
              </span>
              </div>
              <div class="ml-10px zoom-out-btn"
                   @click="getInventoryCoupon">
                <md-icon>autorenew</md-icon>
              </div>
              <div class="ml-10px zoom-out-btn"
                   @click="$modal.show('show-inventory-table-modal')">
                <md-icon>zoom_out_map</md-icon>
              </div>
            </div>
          </div>
          <div class="w_100">
            <md-button class="delete-coupon-btn" @click="showConfirmDeleteCoupon" v-if="couponSelectedRowKeys.length !== 0">
              <md-icon>delete</md-icon>
              <span>Xóa</span>
            </md-button>
            <md-button class="delete-coupon-btn"  @click="showConfirmDeleteCouponByQuery"
                       v-if="pagingParams.total > 0 && ($store.state.auth.user.manage_inventory || [636,842,119,1,2,311].includes(Number($store.state.auth.user.id)))">
              <md-icon>delete_forever</md-icon>
              <span>Xóa {{ pagingParams.total }} phiếu</span>
            </md-button>
            <div class="float-right">
              <pagination-template :class="'mb-10px float-right'" :params="pagingParams" @change-params="changePagingParam" />
            </div>
          </div>
          <inventory-table border-y :columns="transferHeaderTbl.TRANSFER" :table-data="inventoryCouponData"
                           :cell-span-option="cellSpanOption"
                           :cell-style-option="cellStyleOption"
                           :checkbox-option="checkboxOption"/>
        </div>
      </tab>
      <tab name="Xuất điều chuyển" :tab_id="INVENTORY_REPORT_EXPORT_TAB.XUAT_DIEU_CHUYEN">
        <div v-if="tabActive === INVENTORY_REPORT_EXPORT_TAB.XUAT_DIEU_CHUYEN">
          <inventory-filter
              @change="handleFilterChange"
              :pick-type="[INVENTORY_TYPE_OPTIONS.KEYWORK, INVENTORY_TYPE_OPTIONS.NEGATIVE_SKU, INVENTORY_TYPE_OPTIONS.SKU, INVENTORY_TYPE_OPTIONS.GOODS, INVENTORY_TYPE_OPTIONS.STATUS]"
              :tab-active="tabActive"
              :id-filter-date="'xuat_dieu_chuyen'"
          ></inventory-filter>
          <div class="mb-20px d-flex-space-between">
            <div class="heading-600">Danh sách phiếu Xuất điều chuyển</div>
            <div class="d-flex-center">
              <div class="export-excel-btn"
                   @click="dowloadExcel">
                <md-icon>file_download</md-icon>
                <span>
                Xuất Excel
              </span>
              </div>
              <div class="ml-10px zoom-out-btn"
                   @click="getInventoryCoupon">
                <md-icon>autorenew</md-icon>
              </div>
              <div class="ml-10px zoom-out-btn"
                   @click="$modal.show('show-inventory-table-modal')">
                <md-icon>zoom_out_map</md-icon>
              </div>
            </div>
          </div>
          <div class="w_100">
            <md-button class="delete-coupon-btn" @click="showConfirmDeleteCoupon" v-if="couponSelectedRowKeys.length !== 0">
              <md-icon>delete</md-icon>
              <span>Xóa</span>
            </md-button>
            <md-button class="delete-coupon-btn"  @click="showConfirmDeleteCouponByQuery"
                       v-if="pagingParams.total > 0 && ($store.state.auth.user.manage_inventory || [636,842,119,1,2,311].includes(Number($store.state.auth.user.id)))">
              <md-icon>delete_forever</md-icon>
              <span>Xóa {{ pagingParams.total }} phiếu</span>
            </md-button>
            <div class="float-right">
              <pagination-template :class="'mb-10px float-right'" :params="pagingParams" @change-params="changePagingParam" />
            </div>
          </div>
          <inventory-table border-y :columns="transferHeaderTbl.TRANSFER" :table-data="inventoryCouponData"
                           :cell-span-option="cellSpanOption"
                           :cell-style-option="cellStyleOption"
                           :checkbox-option="checkboxOption"/>
        </div>
      </tab>
    </tabs>
    <show-inventory-table-modal :total-stock-data="inventoryCouponData"
                                :total-stock-columns="transferHeaderTbl.TRANSFER"
                                :paging-params="pagingParams"
                                :hidden-export-btn="true"
                                @change-paging-params="changePagingParam"
                                @export="dowloadExcel"/>
  </div>
</template>

<script>

import InventoryFilter from "../Filter";
import InventoryTable from "../Table";
import {
  INVENTORY_EXPORT_TABLE_HEADER,
  INVENTORY_REPORT_EXPORT_TAB,
  INVENTORY_TYPE_OPTIONS
} from "../../../../../const/const";
import {helpers} from "../../../../../helper/helpers";
import PaginationTemplate from "@/pages/Components/PaginationTemplate";
import InventoryService from "@/store/services/InventoryService";
import moment from "moment";
import couponMixin from "@/mixins/coupon-mixin";
import ShowInventoryTableModal from "@/pages/Components/Modal/ShowInventoryTableModal";

export default {
  mixins: [couponMixin],
  name: "TransferCouponContent",
  components: {
    InventoryTable,
    InventoryFilter,
    PaginationTemplate,
    ShowInventoryTableModal
  },
  created() {
    this.tabActive = INVENTORY_REPORT_EXPORT_TAB.NHAP_DIEU_CHUYEN;
  },
  computed: {
    transferHeaderTbl() {
      let headerList = {...INVENTORY_EXPORT_TABLE_HEADER};
      let subHeaderList = headerList['TRANSFER'];
      for (let i = 0; i < subHeaderList.length; i++) {
        if (((this.$store.state.auth.user.manage_inventory && !this.$store.state.auth.user.manage_inventory) || ![636,842,119,1,2,311].includes(Number(this.$store.state.auth.user.id))) && subHeaderList[i].type === "checkbox") {
          subHeaderList.splice(i, 1);
        }

        if (subHeaderList[i].field && subHeaderList[i].field === 'detail_btn') {
          subHeaderList[i].renderBodyCell = ({row, column, rowIndex}, h) => {
            return (
                <span class="material-symbols-outlined show-detail"
                      on-click={() => this.getProductDetail(row)}>info</span>
            );
          };
        }

        if (!((this.$store.state.auth.user.manage_inventory && !this.$store.state.auth.user.manage_inventory) || ![636, 842, 119, 1, 2, 311].includes(Number(this.$store.state.auth.user.id))) &&
            subHeaderList[i].field && subHeaderList[i].field === 'action') {
          subHeaderList[i].renderBodyCell = ({row, column, rowIndex}, h) => {
            return (
                <div class="d-flex">
                  <button class="p-2px cursor-pointer tooltip ml-5px"
                          on-click={() => this.updateOrderCoupon(row)}>
                    <md-icon>edit</md-icon>
                  </button>
                </div>
            );
          }
        }
      }
      return headerList;
    }
  }

}
</script>

<style lang="scss">
.inventory-content__container {

}
</style>